import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

let whatsapp = "https://wa.me/6596886222?text=Appointment%20Request";
let callme = "tel:+6596886222";
let facebook = "https://www.facebook.com/voguehairworkshop";
let googlebiz = "https://goo.gl/maps/vPkeUKZucmVuLdzJA";

$(function () {
	//Modal Window
	function openModal() {
		$(".modal").fadeIn().css("display", "flex");
		$("html, body").addClass("no-scroll");
	}
	function closeModal() {
		$(".modal").fadeOut();
		$("html, body").removeClass("no-scroll");
	}
	$('.emllac').attr("href", callme );
	$('.ppastahw').attr("href", whatsapp );
	$('.ssensiub').attr("href", googlebiz );
	$('.koobecaf').attr("href", facebook );
	$('.appt').attr("href", whatsapp );

	$("[data='modal']").on("click", function (e) {
		e.preventDefault();
		openModal();
	});
	$(".modal .modal-close").on("click", function () {
		closeModal();
	});
	$(document).on("keydown", function (event) {
		if (event.key == "Escape") {
			closeModal();
		}
	});
}); //Document Ready
